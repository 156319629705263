import { Button, Container, Divider, Grid, Link, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC } from 'react';
import ZSEO from '../components/ZSeo';
import ZFooter from '../components/ZFooter';
import shannaTranparent from '../images/shannaTransparent.png';
import { Link as GatsbyLink } from 'gatsby';
import ZTestimonials from '../components/ZTestimonials';

const IndexPage: FC = () => {
  const classes = useStyles();
  return (
    <>
      <ZSEO title='Home' />
      <Grid className={classes.bannerCover}>
        <div className={classes.bannerTopTextWrapperPadding} />
        <Container className={classes.bannerTopTextWrapper} maxWidth='lg'>
          <Grid container justify='center'>
            <Grid container justify='center' item xs={10}>
              <Grid item xs={6}>
                <Grid container justify='flex-start'>
                  <h2 className={classes.bannerTittle}>COPYWRITING</h2>
                  <h2 className={classes.bannerTittleUnderLine}>CONNOISSEUR</h2>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justify='flex-end'>
                  <h1 className={classes.bannerTittle}>THE COOL</h1>
                  <h1 className={classes.bannerTittleUnderLine}>CONTENT MARKETER</h1>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid className={classes.bannerYellow} container justify='center' alignContent='center'>
          <Grid item md={6}>
            <h2>CRAFTING CREATIVE CONTENT AND CREDIBLE COPY</h2>
          </Grid>
        </Grid>
        <Grid className={classes.bannerWhite} container justify='center' alignContent='center'>
          <Grid item md={6} xs={10}>
            <h2>LET'S TAKE THE WRITE STEP TOGETHER</h2>
          </Grid>
        </Grid>
        {/* <Grid container justify='center' spacing={0}>
          <Link to='/contact-us' className={classes.btnContactUs} component={GatsbyLink} color='inherit'>
            <Typography className={classes.btnContactUsText}>WORK WITH US</Typography>
          </Link>
        </Grid> */}
      </Grid>
      <Divider />

      <Container className={classes.conatiner} maxWidth='lg'>
        <ZTestimonials />
      </Container>
      <ZFooter />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  heroButtons: {
    marginTop: theme.spacing(4)
  },

  bannerCover: {
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: `url(${shannaTranparent})`,
    backgroundSize: '600px 800px',
    padding: '120px 0px',
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '400px 533px'
    }
  },
  bannerTopTextWrapperPadding: {
    paddingTop: '150px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '0px'
    }
  },

  bannerTopTextWrapper: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      paddingTop: '0px'
    }
  },
  conatiner: {
    padding: 20
  },
  sectionHeading: {
    color: '#2B9EAC',
    fontWeight: 700,
    fontSize: 20,
    marginTop: 10
  },
  sectionHeadingBottom: {
    width: 70,
    height: 5,
    backgroundColor: '#2B9EAC',

    marginBottom: 20
  },
  link: {
    textDecoration: `none`,
    textTransform: `capitalize`,
    '&:hover': {
      textDecoration: `none`
    }
  },

  bannerTittle: {
    fontWeight: 800,
    fontSize: 23
  },
  bannerTittleUnderLine: {
    fontWeight: 800,
    marginRight: 10,
    marginLeft: 10,
    color: '#BE3CA4',
    fontSize: 23
  },
  bannerYellow: {
    padding: '0px',
    marginTop: '100px',
    backgroundColor: '#FAB32E',
    opacity: '0.9',
    color: 'white',
    textAlign: 'center',
    fontWeight: 800
  },
  bannerWhite: {
    padding: '0px',
    marginTop: '40px',

    backgroundColor: '#ECEDEC',
    opacity: '0.9',
    color: 'black',
    textAlign: 'center',
    fontWeight: 800
  },
  btnContactUs: {
    marginTop: '70px',
    marginBottom: '50px',
    backgroundColor: '#BE3CA4',
    padding: '5px 10px',

    borderRadius: 10,
    color: 'white',
    '&:hover': {
      textDecoration: `none`
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
      paddingTop: '0px'
    }
  },
  btnContactUsText: {
    textDecoration: 'none',
    fontSize: 18,
    fontWeight: 700
  }
}));

export default IndexPage;
