import React, { FC } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Container, Theme } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Paper } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FaArrowCircleRight } from 'react-icons/fa';

export interface Props {
  title?: string;
  description?: string;
}

function SampleNextArrow(props) {
  const classes = useStyles();
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick}>
      <FaArrowCircleLeft className={classes.arrowNext} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const classes = useStyles();
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick}>
      <FaArrowCircleRight className={classes.arrowPrev} />
    </div>
  );
}

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  nextArrow: <SampleNextArrow to='next' />,
  prevArrow: <SamplePrevArrow to='prev' />
};

interface Testimonial {
  heading: string;
  body: string;
  footer: string;
  md: any;
}

const Testimonial: FC<Testimonial> = ({ heading, body, footer, md }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container justify='center'>
        <Grid md={10} xs={12} className={classes.testimonialWrapper}>
          <Paper className={classes.testimonialPaperWrapper}>
            <Typography className={classes.testimonialHeading}>{heading}</Typography>
            <Typography className={classes.testimonialBody}>{body}</Typography>
            <Typography className={classes.testimonialFooter}>{footer}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const ZTestimonials: FC<Props> = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper} container justify='center'>
      <Grid item xs={12} md={12} className={classes.sliderWrapper}>
        <Slider {...settings} className='overflow-hidden'>
          {testimonials.map((t, i) => (
            <Testimonial heading={t.heading} body={t.body} footer={t.footer} md={t.md} />
          ))}
        </Slider>
      </Grid>
      <Grid item xs={12} md={12} className={classes.testimonialHiddenWrapper}>
        {testimonials.map((t, i) => (
          <Testimonial heading={t.heading} body={t.body} footer={t.footer} md={t.md} />
        ))}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 20,
    paddingRight: 20,
    margin: `auto`,
    marginTop: 15
  },
  sliderWrapper: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  slider: {},
  testimonialHiddenWrapper: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  testimonialWrapper: {
    paddingTop: 25,
    paddingBottom: 25,
    textAlign: 'left'
  },
  testimonialPaperWrapper: {
    padding: 30,
    textAlign: 'left',
    borderRadius: 35
  },
  testimonialHeading: {
    color: '#CC29CD',
    fontSize: 20,
    fontStyle: 'italic',
    fontWeight: 700,
    marginBottom: 20
  },
  testimonialBody: {
    color: '#000',
    marginBottom: 20,
    lineHeight: 2.0
  },
  testimonialFooter: {
    color: '#2B9EAC',
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 20
  },
  arrowNext: {
    position: 'absolute',
    top: '50%',
    left: -30,
    color: '#000',
    display: 'block',
    fontSize: 22
  },
  arrowPrev: {
    position: 'absolute',
    top: '50%',
    right: -30,
    color: '#000',
    display: 'block',
    fontSize: 22
  }
}));

const testimonials: Testimonial[] = [
  {
    heading: `“I was successful in achieving what I was aiming to do.”`,
    body: `Thank you to the Write Step for crafting my sponsorship proposal. I was pleasantly surprised and pleased with the finished file and how it portrayed my target goal. Of course, I was successful in achieving what I was aiming to do, so that was a big plus!`,
    footer: 'NICOLINA DIABO, CREATIVE ENTREPRENEUR',
    md: 4
  },
  {
    heading: `“I was so nervous when I initiated the process; I am now more than satisfied.”`,
    body: `Contacting you was the best decision I made in the quest to advance my career.
    I had no idea I could look this good on paper. Seeing my Curriculum Vitae boosted my confidence to a new level.
    I now feel so positive that I will be able to secure employment in the organisation I desire.
    Thank you for being so patient and understanding through this process. You went above and beyond for me and I can't thank you enough.
    I was so nervous when I initiated the process; I am now more than satisfied.
    I have recommended your services to my network, after seeing my CV, they are excited to have you do theirs. Thank you so much.  `,
    footer: 'TREESIA NIBBS, MARKETING AND COMMS. SPECIALIST ',
    md: 4
  },
  {
    heading: `“Everything for this project was as expected; friendly attitude, quality writing and above and beyond service”`,
    body: `I've known Shanna-Kay as a skilled writer for quite some time, so it was a no brainer to approach her for her services. This isn't my first rodeo with her; she has worked on the copy for my company's website, and I was pleased. Everything for this project was as expected; friendly attitude, quality writing and above and beyond service. She is highly experienced and wonderfully patient. A rare combination that's resulted in me recommending The Write Step to many of my clients and colleagues. She will be seeing me again!`,
    footer: 'CARLYLE GRANT, MANAGING DIRECTOR, DIGITAL DANES',
    md: 4
  },

  {
    heading: `“I’m just blown away by how amazing everything looks and sounds...Like Michelle Obama wrote it, lol.`,
    body: `I Just wish I could post how amazing this resume looks! No goodie, mi neva know me a goodaz on paper until
    now. The Write Step shell dung mi resume. And no, I was not paid to say these things. I’m just blown away by
    how amazing everything looks and sounds...Like Michelle Obama wrote it, lol…this is the best resume I’ve ever had.” `,
    footer: 'DR. MICKHA  FRANCIS,  VETERINARIAN',
    md: 4
  },
  {
    heading: `"I was also motivated and inspired!”`,
    body: `The Write Step was heaven-sent, and exactly what I needed when I was starting my business page on Instagram. I had no sense of direction,  no  form of theme, colour coordination or marketing strategy. My profile photo and tagline were the first steps, and that’s when reality hit me. I was launching my business! I know the business objective was to get my page up, but I was also motivated and inspired! I took the write step  `,
    footer: 'KERASHIA MALCOLM, FOUNDER, THE SKIN BAR JM',
    md: 4
  },
  {
    heading: `“I was successful in achieving what I was aiming to do”`,
    body: `I was very pleased with the end product, it looked professionally done and suited my needs. I also appreciated how accommodating you were and your willingness to have your customer satisfied.`,
    footer: 'DR.YENDI BUCKERIDGE, GENERAL PRACTITIONER ',
    md: 4
  }
];

export default ZTestimonials;
